/* eslint-disable max-lines-per-function */
import { track } from "jiffy-analytics";
import idleCallback, { timings } from "common/utils/ui/idle_callback";
import initKlarnaCreditBanner from "components/jiffy/klarna-credit-banner/klarna-credit-banner";

const $getKlarnaPlacement = () => document.querySelector("klarna-placement");

const trackKlarnaLearnMoreClicked = () => {
  const { context } = $getKlarnaPlacement()?.dataset || {};
  track("payment_solution_learn_more_clicked", {
    payment_solution: "klarna",
    context
  });
};

const loadSDK = (clientId, src) => {
  const script = document.createElement("script");
  script.async = 1;
  script.src = src;
  script.setAttribute("data-client-id", clientId);

  document.body.appendChild(script);
};

const mobileModalEnhancement = () => {
  let scrollPosition = null;

  const callback = mutationsList => {
    mutationsList.forEach(mutation => {
      if (mutation.type !== "childList") return;
      if (!(mutation.addedNodes instanceof NodeList)) return;

      const nodesArray = [...mutation.addedNodes];
      if (!nodesArray.length) {
        if (scrollPosition !== null) {
          document.documentElement.classList.remove("locked-mobile");
          window.scrollTo({ top: scrollPosition });
          scrollPosition = null;
        }

        return;
      }

      const klarnaIframe = nodesArray.find(({ tagName }) => tagName === "KLARNA-OSM-INTERSTITIAL");
      if (klarnaIframe) {
        scrollPosition = window.scrollY;
        document.documentElement.style.setProperty(
          "--window-inner-height",
          `${window.innerHeight}px`
        );
        document.documentElement.classList.add("locked-mobile");
        trackKlarnaLearnMoreClicked();
      }
    });
  };

  const observer = new MutationObserver(callback);
  observer.observe(document.body, { attributes: false, childList: true, subtree: false });
};

idleCallback(() => {
  const $klarnaServicesPack = document.querySelector("#klarna-services-pack");
  if (!$klarnaServicesPack) return;
  const { clientId, src } = $klarnaServicesPack.dataset;

  loadSDK(clientId, src);
  mobileModalEnhancement();
  initKlarnaCreditBanner();
}, timings.klarnaBanner);
