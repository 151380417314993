import { waitForWindowVariable } from "common/utils/ui/wait_for";
import "./klarna-credit-banner.scss";

export const initWithDynamicData = cartData => {
  const { cart_total: cartTotal = 0 } = cartData;
  const $banners = document.querySelectorAll(".js-klarna-credit-banner");

  if ($banners.length > 0) {
    waitForWindowVariable("Klarna", () => {
      showBanners($banners, cartTotal);
    });
  }
};

const showBanners = ($banners, cartTotal) => {
  const defaultAmount = 1000;
  const amount = Math.round(parseFloat(cartTotal * 100));
  if (amount > defaultAmount) {
    $banners.forEach($banner => {
      $banner.dataset.purchaseAmount = amount;
    });
    window.Klarna?.OnsiteMessaging?.refresh();
  }

  $banners.forEach($banner => {
    $banner.classList.remove("hidden");
  });
};

export default () => {
  if (window.dynamicDataLoaded) {
    initWithDynamicData(document.pdpDynamicData);
  } else {
    document.addEventListener(
      "dynamicDataLoaded",
      () => initWithDynamicData(document.pdpDynamicData),
      false
    );
  }
};
